import React, { FC, Fragment, useEffect, useState } from 'react';
import { RegionDropdown } from 'react-country-region-selector';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row
} from 'reactstrap';
import { RMDEntry } from '../interfaces/rmd';
import { StirShakenInfo } from '../interfaces/stirShaken';
import { validEmailRegex } from '../lib/regex';
import { gerenerateStirShakenRequestObj, validateStirShakenInputFields } from '../lib/utilities';
import { updateHopObject } from '../redux/hop/thunks';
import { getValidateEmailApi } from '../redux/messaging/apiCalls';
import { getAllProviderNames } from '../redux/provider/apiCalls';
import { stateMappings } from '../redux/stateMappings';
import CountryDropdown, { getCountryCodeByName } from './HopDetail/CountryDropdown';
import RmdSearcher from './RmdSearcher';
import StirShakenBody from './StirShakenBody';
import InputError from './inputError';
import InputFormGroup from './inputFormGroup';
import TimezoneDropdown from './TimezoneDropdown';
import { CountryName } from 'country-region-data';
interface IProps {
  isOpen: boolean;
  toggle: () => void;
  handleProviderChange: (e: any) => void;
  updateHopObject: Function;
  hopId: number;
  userEmail: string;
}

interface AddError {
  businessName: string;
  addressLine1: string;
  contactName: string;
  country: string;
  city: string;
  region: string;
  contactPhone: string;
  contactEmail: string;
  extraContactEmail: string;
  postalCode: string;
  stepsTaken: string;
  explanation: string;
  acknowledge?: string;
  signedStirShaken: boolean;
  stirShakenToken: boolean;
  destinationNumber: boolean;
  callingNumber: boolean;
  timeStamp: boolean;
  attestation: boolean;
  timezone: string;
}

const defaultAddError = (): AddError => ({
  businessName: '',
  addressLine1: '',
  contactName: '',
  country: '',
  city: '',
  region: '',
  contactPhone: '',
  contactEmail: '',
  extraContactEmail: '',
  postalCode: '',
  stepsTaken: '',
  explanation: '',
  acknowledge: '',
  signedStirShaken: false,
  stirShakenToken: false,
  destinationNumber: false,
  callingNumber: false,
  timeStamp: false,
  attestation: false,
  timezone: ''
});

const defaultStirShanekInfo: StirShakenInfo = {
  stirShakenToken: '',
  signedStirShaken: null,
  attestationRadio: '',
  destinationNumber: '',
  callingNumber: '',
  timeStamp: '',
  originationIdentifier: '',
  isCallSigner: false
};

const checkZeroBounceError = (status: string) => {
  switch (status) {
    case 'BOUNCE':
      return 'SES blocked';
    case 'COMPLAINT':
      return 'SES blocked';
    case 'does_not_accept_mail':
      return 'domain error';
    case 'failed_syntax_check':
      return 'fail RFC syntax protocol';
    case 'possible_typo':
      return 'misspelled popular domain';
    case 'mailbox_not_found':
      return 'email do not exist';
    case 'no_dns_entries':
      return 'incomplete DNS Record';
    case 'mailbox_quota_exceeded':
      return 'exceeded space quota';
    case 'unroutable_ip_address':
      return 'un-routable IP address';
    case 'global_suppression':
      return 'email found in many popular global suppression lists';
    case 'possible_trap':
      return 'email contain keywords that might correlate to possible spam';
    case 'role_based':
      return 'email belong to a position or a group of people';
    case 'disposable':
      return 'temporary email';
    case 'toxic':
      return 'abuse, spam, or bot created email';
    case 'role_based_catch_all':
      return 'email belong to a catch_all domain.';
    case 'mx_forward':
      return 'domain error';
    case 'wrongFormat':
      return 'invalid email';
    default:
      return 'unknown error';
  }
};

export const GetZeroBounceError = async (contactEmail: string) => {
  try {
    const { data } = await getValidateEmailApi(contactEmail);
    if (data && (data.status === 'invalid' || data.aws_reason)) {
      return checkZeroBounceError(data.sub_status ? data.sub_status : data.aws_reason);
    }
    return '';
  } catch (error: any) {
    console.log(error);
    return error;
  }
};

const NewProviderPopup: FC<IProps> = ({
  isOpen,
  toggle,
  handleProviderChange,
  updateHopObject,
  hopId,
  userEmail
}) => {
  const [providerError, setProviderError] = useState<AddError>(defaultAddError());
  const [businessName, setBusinessName] = useState('');
  const [contactName, setContactName] = useState('');
  const [country, setCountry] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [extraContactEmail, setExtraContactEmail] = useState('');
  const [city, setCity] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [region, setRegion] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [comment, setComment] = useState('');
  const [acknowledge, setAcknowledge] = useState(false);
  const [stirShakenInfo, setStirShakenInfo] = useState<StirShakenInfo>(defaultStirShanekInfo);
  const [similarProviders, setSimilarProviders] = useState<any[] | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rmdBusinessName, setRmdBusinessName] = useState('');
  const [rmd, setRmd] = useState<RMDEntry | null>(null);
  const [isManualProvider, setIsManualProvider] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [timeZone, setTimezone] = useState('');
  const togglePopup = () => {
    toggle();
    setIsManualProvider(false);
    setRmd(null);
    setRmdBusinessName('');
    clearData();
  };

  useEffect(() => () => setIsModalOpen(false), []);

  useEffect(() => {
    if (rmd === null) {
      setBusinessName('');
      setCountry('');
      setAddressLine1('');
      setContactPhone('');
      setContactName('');
      setCity('');
      setContactEmail('');
      setValidEmail(false);
      return;
    }
    if (rmd.providerName) {
      handleProviderChange({ value: rmd.providerId, label: rmd.providerName });
      togglePopup();
      return;
    }
    setBusinessName(rmd.businessName || '');
    setContactName(rmd.robocallMitigationContactName || '');
    setCountry((rmd.country || '').replace(' of America', '').trim());
    setAddressLine1(rmd.businessAddress || '');
    setContactEmail(rmd.contactEmail || '');
    setCity(rmd.contactCity || '');
    setContactPhone(rmd.contactTelephoneNumber || '');
    setRegion(rmd.contactState || rmd.contactCity || '');
    setPostalCode(rmd.contactZipCode || '');
    setProviderError(defaultAddError());
    getZeroBounce();
  }, [rmd]);

  useEffect(() => {
    if (!contactEmail) return;
    if (!validEmailRegex.test(contactEmail))
      setProviderError({ ...providerError, contactEmail: 'This email address is not valid !' });
  }, [contactEmail]);
  useEffect(() => {
    if (!extraContactEmail) return;
    if (!validEmailRegex.test(extraContactEmail))
      setProviderError({
        ...providerError,
        extraContactEmail: 'This email address is not valid !'
      });
  }, [extraContactEmail]);
  useEffect(() => {
    setAcknowledge(!isManualProvider);
    setDisabledSubmit(false);
  }, [isManualProvider]);
  const getZeroBounce = async () => {
    if (rmd && rmd.contactEmail) {
      const zeroBounce = await getZeroBounceError(rmd.contactEmail);
      setValidEmail(zeroBounce);
    }
  };
  const toggleModal = () => setIsModalOpen((prevState) => !prevState);
  const setCountryFunction = (label: string) => {
    setCountry(label);
    setProviderError({ ...providerError, country: '' });
  };
  const setTimezoneFunction = (label: string) => {
    setTimezone(label);
    setProviderError({ ...providerError, timezone: '' });
  };
  const handleInputChange = (key: string, e: any) => {
    switch (key) {
      case 'businessName':
        setBusinessName(e.target.value);
        setProviderError({ ...providerError, businessName: '' });
        break;
      case 'contactName':
        setContactName(e.target.value);
        setProviderError({ ...providerError, contactName: '' });
        break;
      case 'addressLine1':
        setAddressLine1(e.target.value);
        break;
      case 'contactEmail':
        setContactEmail(e.target.value);
        if (providerError.contactEmail && providerError.contactEmail.includes('issue'))
          setDisabledSubmit(false);
        setProviderError({ ...providerError, contactEmail: '' });
        break;
      case 'extraContactEmail':
        setExtraContactEmail(e.target.value);
        if (
          providerError.extraContactEmail.includes('issue') ||
          providerError.contactEmail.includes('issue')
        )
          setDisabledSubmit(false);
        setProviderError({ ...providerError, extraContactEmail: '' });
        break;
      case 'city':
        setCity(e.target.value);
        break;
      case 'contactPhone':
        setContactPhone(e.target.value);
        break;
      case 'zipCode':
        setPostalCode(e.target.value);
        break;
      case 'comment':
        setComment(e.target.value);
        break;
      case 'region':
        setRegion(e);
        break;
      case 'acknowledge':
        setAcknowledge(!acknowledge);
        setProviderError({ ...providerError, acknowledge: '' });
        break;
    }
  };

  const providerSubmitForm = () => {
    updateHopObject({
      hopId: hopId,
      delegatedTo: userEmail,
      status: 6,
      newProvider: {
        newProviderAddress: addressLine1,
        newProviderCity: city,
        newProviderContactEmail: contactEmail,
        newProviderExtraContactEmail: extraContactEmail || null,
        newProviderContactName: contactName,
        newProviderContactPhone: contactPhone,
        newProviderCountry: country,
        newProviderStateRegion: region,
        newProviderZip: postalCode,
        newProviderName: businessName,
        newProviderReason: comment,
        newRmd: rmd,
        newProviderTimeZone: timeZone
      },
      stirShakenRequest: gerenerateStirShakenRequestObj(stirShakenInfo)
    });
  };

  const getZeroBounceError = async (contactEmail: string) => {
    try {
      const zeroBounceError = await GetZeroBounceError(contactEmail);
      if (zeroBounceError) {
        const errorManually = rmd
          ? ' Please provide a different email'
          : ' Please submit a valid email';
        setDisabledSubmit(true);
        if (validEmail && extraContactEmail) {
          setProviderError({
            ...providerError,
            extraContactEmail: 'The email has an issue - ' + zeroBounceError + '.' + errorManually
          });
        } else {
          setProviderError({
            ...providerError,
            contactEmail: 'The email has an issue - ' + zeroBounceError + '.' + errorManually
          });
        }
        return true;
      }
      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  };

  const checkForExistingProvider = async (e: any) => {
    const stirShakenErrors = validateStirShakenInputFields(providerError, stirShakenInfo);
    let pError = defaultAddError();
    if (businessName === '') {
      pError.businessName = 'Please provide a Business Name !';
    }
    if (!contactName) {
      pError.contactName = 'Please provide a Contact Name !';
    }
    if (country === '') {
      pError.country = 'Please provide a country !';
    }
    if (timeZone === '') {
      pError.timezone = 'Please provide a timezone !';
    }
    if (contactEmail === '') {
      pError.contactEmail = 'Please provide an email !';
    } else if (!validEmailRegex.test(contactEmail))
      pError.contactEmail = 'This email address is not valid !';
    if (extraContactEmail === '' && validEmail) {
      pError.extraContactEmail = 'Please provide an email !';
    } else if (!validEmailRegex.test(extraContactEmail) && validEmail)
      pError.extraContactEmail = 'This email address is not valid !';
    if (!acknowledge) {
      pError.acknowledge = 'You must acknowledge in order to submit !';
    }
    if (stirShakenErrors) {
      pError.signedStirShaken = stirShakenErrors.signedStirShaken;
      pError.callingNumber = stirShakenErrors.callingNumber;
      pError.destinationNumber = stirShakenErrors.destinationNumber;
      pError.stirShakenToken = stirShakenErrors.stirShakenToken;
      pError.timeStamp = stirShakenErrors.timeStamp;
      pError.attestation = stirShakenErrors.attestation;
    }
    setProviderError(pError);
    if (
      pError.businessName ||
      pError.contactName ||
      pError.contactEmail ||
      pError.extraContactEmail ||
      pError.country ||
      pError.timezone ||
      pError.acknowledge ||
      pError.callingNumber ||
      pError.destinationNumber ||
      pError.signedStirShaken ||
      pError.stirShakenToken ||
      pError.timeStamp ||
      pError.attestation
    ) {
      setSubmitPressed(false);
      return;
    }

    if (await getZeroBounceError(validEmail ? extraContactEmail : contactEmail)) {
      setSubmitPressed(false);
      return;
    }
    toggle();

    if (e && e.target) e.preventDefault(e.target.value);

    const { data } = await getAllProviderNames();
    const filteredProviders = data.data.filter((providerElement: any) =>
      providerElement.name.toLowerCase().includes(businessName.toLowerCase())
    );

    if (filteredProviders && filteredProviders.length > 0) {
      setSimilarProviders(filteredProviders);
      toggleModal();
    } else {
      providerSubmitForm();
    }
  };

  const clearData = () => {
    setComment('');
    setBusinessName('');
    setContactName('');
    setContactEmail('');
    setExtraContactEmail('');
    setCountry('');
    setAddressLine1('');
    setCity('');
    setContactPhone('');
    setProviderError(defaultAddError());
    setPostalCode('');
    setStirShakenInfo(defaultStirShanekInfo);
    setTimezone('');
  };

  return (
    <Fragment>
      <Popover
        className="hop-provider-pop"
        isOpen={isOpen}
        target="Popover1"
        toggle={togglePopup}
        placement="bottom"
      >
        {!isManualProvider && !rmd && (
          <div>
            {' '}
            <PopoverHeader style={{ fontSize: '20px' }}>
              Find provider in FCC Robocall Mitigation Database
            </PopoverHeader>
            <hr className="hr-line" />
            <PopoverBody className="pb-5">
              <RmdSearcher setRmd={setRmd} setBusinessName={setRmdBusinessName} />
              <div className="d-flex justify-content-center mt-5">
                <div className="bottom-options">
                  <Button className="btn-default telecom-btn" onClick={togglePopup} color="light">
                    Cancel
                  </Button>
                  {!!rmdBusinessName && (
                    <button
                      type="button"
                      className="btn btn-link"
                      style={{ marginLeft: '10px' }}
                      onClick={() => {
                        setIsManualProvider(true);
                        setRmd(null);
                      }}
                    >
                      Manually enter a provider
                    </button>
                  )}
                </div>
              </div>
            </PopoverBody>
          </div>
        )}
        {(isManualProvider || !!rmd) && (
          <Fragment>
            <PopoverHeader style={{ fontSize: '20px' }}>Add A New Provider</PopoverHeader>
            <PopoverBody className="pb-5">
              <Row>
                <Col md="6">
                  <Label className="telecom-label">
                    Provider Business Name <i className="fa fa-asterisk asterisk" />
                  </Label>
                  <InputFormGroup
                    isReadonly={!isManualProvider && rmd?.businessName !== null}
                    inputName="p-businessName"
                    inputId="p-businessName"
                    inputClassName="input-hop"
                    inputValue={businessName}
                    inputPlaceholder=""
                    inputOnChange={(e) => handleInputChange('businessName', e)}
                    inputAutoComplete="off"
                    errorMessage={providerError.businessName}
                  />
                </Col>
                <Col md="6">
                  <Label className="telecom-label">
                    Individual Contact Name <i className="fa fa-asterisk asterisk" />
                  </Label>
                  <InputFormGroup
                    isReadonly={!isManualProvider && rmd?.robocallMitigationContactName !== null}
                    inputName="p-contactName"
                    inputId="p-contactName"
                    inputClassName="input-hop"
                    inputValue={contactName}
                    inputPlaceholder=""
                    inputOnChange={(e) => handleInputChange('contactName', e)}
                    inputAutoComplete="off"
                    errorMessage={providerError.contactName}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Label className="telecom-label">
                    Country
                    <i className="fa fa-asterisk asterisk" />
                  </Label>
                  {isManualProvider || !rmd?.country ? (
                    <CountryDropdown
                      className={'country-dropdown'}
                      name={'hopdetail-country-dropdown'}
                      value={country}
                      setCountry={setCountryFunction}
                      extraOption={false}
                    />
                  ) : (
                    <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
                      "{country}"
                    </p>
                  )}
                  {providerError.country && (
                    <InputError className="telecom-input-error">{providerError.country}</InputError>
                  )}
                </Col>
                <Col md="6">
                  <Label className="telecom-label">Address</Label>
                  <InputFormGroup
                    isReadonly={!isManualProvider && !!rmd?.businessAddress}
                    inputName="p-addressLine1"
                    inputId="p-addressLine1"
                    inputClassName="input-hop"
                    inputValue={addressLine1}
                    inputPlaceholder=""
                    inputOnChange={(e) => handleInputChange('addressLine1', e)}
                    inputAutoComplete="off"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md="6">
                  <Label className="telecom-label">
                    Provider Contact Email <i className="fa fa-asterisk asterisk" />
                  </Label>
                  <InputFormGroup
                    isReadonly={!isManualProvider && rmd?.contactEmail !== null}
                    inputName="p-contactEmail"
                    inputId="p-contactEmail"
                    inputClassName="input-hop"
                    inputValue={contactEmail}
                    inputPlaceholder=""
                    inputOnChange={(e) => handleInputChange('contactEmail', e)}
                    inputAutoComplete="off"
                    errorMessage={providerError.contactEmail ? providerError.contactEmail : null}
                  />
                </Col>
                <Col md="6">
                  <Label className="telecom-label">City</Label>
                  <InputFormGroup
                    isReadonly={!isManualProvider && rmd?.contactCity !== null}
                    inputName="p-city"
                    inputId="p-city"
                    inputClassName="input-hop"
                    inputValue={city}
                    inputPlaceholder=""
                    inputOnChange={(e) => handleInputChange('city', e)}
                    inputAutoComplete="off"
                  />
                </Col>
              </Row>
              {validEmail && (
                <Row className="mb-3">
                  <Col md="6">
                    <Label className="telecom-label">
                      Extra Provider Contact Email <i className="fa fa-asterisk asterisk" />
                    </Label>
                    <InputFormGroup
                      inputName="p-contactEmail"
                      inputId="p-contactEmail"
                      inputClassName="input-hop"
                      inputValue={extraContactEmail}
                      inputPlaceholder=""
                      inputOnChange={(e) => handleInputChange('extraContactEmail', e)}
                      inputAutoComplete="off"
                      errorMessage={
                        providerError.extraContactEmail ? providerError.extraContactEmail : null
                      }
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md="6">
                  <Label className="telecom-label">Provider Contact Phone</Label>
                  <InputFormGroup
                    isReadonly={!isManualProvider && rmd?.contactTelephoneNumber !== null}
                    inputName="p-contactPhone"
                    inputId="p-contactPhone"
                    inputClassName="input-hop"
                    inputValue={contactPhone}
                    inputPlaceholder=""
                    inputOnChange={(e) => handleInputChange('contactPhone', e)}
                    inputAutoComplete="off"
                  />
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="5" className="pe-1">
                      <Label className="telecom-label">State / Region</Label>
                      <RegionDropdown
                        disabled={!isManualProvider && rmd?.contactCity === null && city === null}
                        country={country}
                        value={region}
                        classes={'region-dropdown'}
                        name={'hopdetail-region-dropdown'}
                        onChange={(e) => handleInputChange('region', e)}
                      />
                    </Col>
                    <Col md="4" className="ps-0">
                      <Label className="telecom-label">Zip / Postal Code</Label>
                      <InputFormGroup
                        isReadonly={!isManualProvider && rmd?.contactZipCode !== null}
                        inputName="p-zipCode"
                        inputId="p-zipCode"
                        inputClassName="input-hop"
                        inputValue={postalCode}
                        inputPlaceholder=""
                        inputOnChange={(e) => handleInputChange('zipCode', e)}
                        inputAutoComplete="off"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="row">
                <div className="col-12 col-md-4">
                  <Label className="telecom-label">
                    Time Zone <i className="fa fa-asterisk asterisk" />
                  </Label>
                  <TimezoneDropdown
                    countryCode={getCountryCodeByName(country as CountryName)}
                    value={timeZone}
                    setTimezone={setTimezoneFunction}
                  />
                </div>
                {providerError.timezone && (
                  <InputError className="telecom-input-error">{providerError.timezone}</InputError>
                )}
              </div>
              <Row>
                <Col md="12">
                  <Label className="telecom-label">
                    Please provide ITG with any additional information we may need to know about
                    this New Provider
                  </Label>
                  <InputFormGroup
                    isReadonly={false}
                    isTextarea
                    inputName="p-comment"
                    inputId="p-comment"
                    inputClassName="input-hop"
                    inputValue={comment}
                    inputOnChange={(e) => handleInputChange('comment', e)}
                    inputPlaceholder=""
                    inputAutoComplete="off"
                  />
                </Col>
              </Row>
              <div className="col">
                <StirShakenBody
                  setStirShakenInfo={setStirShakenInfo}
                  stirShakenInfo={stirShakenInfo}
                  stirShakenModalErrors={providerError}
                  setStirShakenModalErrors={setProviderError}
                />
              </div>

              {isManualProvider ? (
                <div className="d-flex flex-column justify-content-center pt-5">
                  <div className="d-flex flex-row justify-content-center text-center">
                    <input
                      checked={acknowledge}
                      onChange={(e) => handleInputChange('acknowledge', e)}
                      type="checkbox"
                      className="ms-3"
                      style={{ height: 20, width: 20 }}
                    />
                    <label className="telecom-label" style={{ width: '65%' }}>
                      I am aware and acknowledge that this provider is not in the FCC's Robocall
                      Mitigation Database.
                    </label>
                  </div>
                  <div className="d-flex flex-row justify-content-center text-center">
                    <label className="telecom-label" style={{ width: '70%', marginTop: 20 }}>
                      * Please contact{' '}
                      <a
                        style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => (window.location.href = 'mailto:support@tracebacks.org')}
                      >
                        support@tracebacks.org{' '}
                      </a>
                      with a link to the provider's filing if you believe the provider is in the
                      Robocall Mitigation Database
                    </label>
                  </div>
                </div>
              ) : null}
              {providerError && (
                <InputError className="telecom-input-error text-center pb-5">
                  {providerError.acknowledge}
                </InputError>
              )}
              <div className="d-flex flex-wrap justify-content-between">
                <Button
                  className="btn-default telecom-btn"
                  color="light"
                  onClick={() => {
                    setIsManualProvider(false);
                    setRmdBusinessName('');
                    setRmd(null);
                    clearData();
                  }}
                >
                  Back
                </Button>
                <div>
                  <Button className="btn-default telecom-btn" onClick={togglePopup} color="light">
                    Cancel
                  </Button>
                  <Button
                    className="telecom-btn ms-4 "
                    onClick={(e) => {
                      setSubmitPressed(true);
                      checkForExistingProvider(e);
                    }}
                    disabled={disabledSubmit || submitPressed}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </PopoverBody>
          </Fragment>
        )}
      </Popover>

      <Modal centered isOpen={isModalOpen} className="already-existing-modal" toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Similar provider/s already exist.</ModalHeader>
        <ModalBody>
          There is a list of all similar providers (click on any of the listed options in order to
          select the already existing provider):
          <ul style={{ margin: '24px auto' }}>
            {similarProviders &&
              React.Children.toArray(
                similarProviders.map((provider) => (
                  <li
                    className="similar-provider"
                    onClick={() => {
                      handleProviderChange({
                        label: provider.name,
                        value: provider.providerId
                      });
                      toggleModal();
                    }}
                  >
                    {provider.name}
                  </li>
                ))
              )}
          </ul>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Button className="btn-default telecom-btn" color="light" onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            className="telecom-btn ms-4"
            onClick={() => {
              toggleModal();
              providerSubmitForm();
            }}
          >
            Submit anyway
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    hopId: sm.hop.hop.hopId,
    userEmail: sm.user.email
  };
};

const mapActionsToProps = { updateHopObject };
export default connect(mapStateToProps, mapActionsToProps)(NewProviderPopup);
