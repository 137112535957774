import React from 'react';
import Select from 'react-select';
import { getCountry, getTimezone } from 'countries-and-timezones';
import { SelectOption } from './CustomSelect';

interface IProps {
  countryCode: string;
  value: string;
  setTimezone: Function;
}
const parseTimezone = (timeZone: string) => {
  const timezoneInfo = getTimezone(timeZone);
  if (timezoneInfo) {
    const offset = timezoneInfo.utcOffset / 60;
    const dstOffset = timezoneInfo.dstOffset / 60;
    const offsetStr = offset < 0 ? `-${Math.abs(offset)}` : `+${offset}`;
    const dstStr =
      timezoneInfo.dstOffset !== undefined
        ? dstOffset < 0
          ? `-${Math.abs(dstOffset)}`
          : `+${dstOffset}`
        : 'no DST';
    return `${timezoneInfo.name} (UTC ${offsetStr}, DST ${dstStr})`;
  } else return timeZone;
};
const getTimzoneByCountryCode = (countryCode: string) => {
  const timezones = getCountry(countryCode)?.timezones;
  return timezones?.map(
    (timeZone): SelectOption => ({ value: timeZone, label: parseTimezone(timeZone) })
  );
};
const TimezoneDropdown: React.FC<IProps> = ({ countryCode, value, setTimezone }) => {
  const options = getTimzoneByCountryCode(countryCode);
  return (
    <Select
      className="customselect-small"
      placeholder="Select timezone"
      options={options}
      value={options?.find((v) => v.value === value)}
      onChange={(e: any) => setTimezone(e.value)}
      styles={{
        placeholder: (base) => ({
          ...base,
          marginLeft: '5px'
        }),
        singleValue: (base) => ({
          ...base,
          marginLeft: '5px'
        })
      }}
    />
  );
};
export default TimezoneDropdown;
